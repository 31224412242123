<template>
    <div class="container-custom">
        <Header />

        <BackVideos @backToVideos="backToVideos()" v-if="$store.state.endPage == 1" />
        <BackDevice v-if="$store.state.endPage == 0" />
        <div
            class="step text-center"
            v-if="$store.state.endPage == 1"
            :style="{'background-image': 'url(' + require('@/assets/bg-images/2.4_'+$store.state.currentVideo+'.png') + ')', 'background-size': 'cover'}"
        >
            <div class="controlls-box">
                <div class="btn btn--controll-box" @click="reiaVideo()">
                    <i class="icon icon--replay"></i>
                    <br />
                    <span>
                        Reia
                        <br />video-ul
                    </span>
                </div>
                <div
                    class="btn btn--controll-box"
                    v-if="$store.state.nextVideoNr < 5"
                    @click="playVideo2()"
                >
                    <i class="icon icon--play--next"></i>
                    <br />
                    <span>
                        Video-ul
                        <br />urmator
                    </span>
                </div>
            </div>
            <router-link
                @click="backToVideos()"
                to="/faq#iqos24"
                class="btn btn--primary btn--lg"
            >Întrebări frecvente</router-link>
        </div>

        <div v-show="$store.state.endPage == 0" class="step step--videos text-center">
            <div
                @click="playVideo('video-1')"
                class="video-item video-item--1 d-flex"
                :style="{'background-image': 'url(' + require('@/assets/thumbnails/IQOS_2.4plus/Unboxing_2.4.png') + ')'}"
            >
                <div class="align-self-center w-100">
                    <p>Ce conține kit-ul tău IQOS</p>
                    <i class="icon icon--play"></i>
                </div>
                <video
                    @webkitfullscreenchange="stopFull($event)"
                    @mozfullscreenchange="stopFull($event)"
                    @msfullscreenchange="stopFull($event)"
                    @fullscreenchange="stopFull($event)"
                    @ended="ended($event)"
                    class="video-item__video"
                    id="video-1"
                    controls
                    allowfullscreen
                >
                    <source
                        src="https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+2.4%2B/IQOS_2_4_Unboxing.mp4"
                    />
                </video>
            </div>

            <div
                @click="playVideo('video-2')"
                class="video-item video-item--3 d-flex"
                :style="{'background-image': 'url(' + require('@/assets/thumbnails/IQOS_2.4plus/Charging_2.4.png') + ')'}"
            >
                <div class="align-self-center w-100">
                    <p>Cum să-ți încarci dispozitivul</p>
                    <i class="icon icon--play"></i>
                </div>
                <video
                    @webkitfullscreenchange="stopFull($event)"
                    @mozfullscreenchange="stopFull($event)"
                    @msfullscreenchange="stopFull($event)"
                    @fullscreenchange="stopFull($event)"
                    @ended="ended($event)"
                    class="video-item__video"
                    id="video-2"
                    controls
                    allowfullscreen
                >
                    <source
                        src="https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+2.4%2B/IQOS_2_4_Charge.mp4"
                    />
                </video>
            </div>
            <div
                @click="playVideo('video-3')"
                class="video-item video-item--2 d-flex"
                :style="{'background-image': 'url(' + require('@/assets/thumbnails/IQOS_2.4plus/Setup_2.4.png') + ')'}"
            >
                <div class="align-self-center w-100">
                    <p>Cum să folosești dispozitivul</p>
                    <i class="icon icon--play"></i>
                </div>
                <video
                    @webkitfullscreenchange="stopFull($event)"
                    @mozfullscreenchange="stopFull($event)"
                    @msfullscreenchange="stopFull($event)"
                    @fullscreenchange="stopFull($event)"
                    @ended="ended($event)"
                    class="video-item__video"
                    id="video-3"
                    controls
                    allowfullscreen
                >
                    <source
                        src="https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+2.4%2B/IQOS_2_4_SetUp.mp4"
                    />
                </video>
            </div>
            <div
                @click="playVideo('video-4')"
                class="video-item video-item--4 d-flex"
                :style="{'background-image': 'url(' + require('@/assets/thumbnails/IQOS_2.4plus/cleaning_2.4.png') + ')'}"
            >
                <div class="align-self-center w-100">
                    <p>Cum să-ți cureți dispozitivul</p>
                    <i class="icon icon--play"></i>
                </div>
                <video
                    @webkitfullscreenchange="stopFull($event)"
                    @mozfullscreenchange="stopFull($event)"
                    @msfullscreenchange="stopFull($event)"
                    @fullscreenchange="stopFull($event)"
                    @ended="ended($event)"
                    class="video-item__video"
                    id="video-4"
                    controls
                    allowfullscreen
                >
                    <source
                        src="https://iqos-pocket.s3-eu-west-1.amazonaws.com/IQOS+2.4%2B/IQOS_2_4_CleanUp.mp4"
                    />
                </video>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/Partials/Header";
import BackVideos from "@/views/Partials/BackVideos";
import BackDevice from "@/views/Partials/BackDevice";

import { mapActions } from "vuex";

export default {
    name: "Iqos24",
    data: function() {
        return {
            test: ""
        };
    },
    components: {
        Header,
        BackVideos,
        BackDevice
    },
    methods: {
        ...mapActions({
            playVideo: "playVideo",
            ended: "ended",
            reiaVideo: "reiaVideo",
            playVideo2: "playVideo2",
            backToVideos: "backToVideos",
            stopFull: "stopFull"
        })
    }
};
</script>
