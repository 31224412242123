<template>
    <div class="cont">
        <Header />

        <BackDevice />
        <div class="container-faq">

            <label class="faq-title" @click="deschide('#iqos3')">
                IQOS
                3
                <i v-if="deschis != '#iqos3'" class="arrow arrow-down"></i>
                <i v-if="deschis == '#iqos3'" class="arrow arrow-up"></i>
            </label>
            <div
                :class="this.$route.hash == '#iqos3' ?'tab--dev text-center order-1':'tab--dev text-center  order-4'"
            >
                <transition name="accordion">
                    <div v-if="deschis == '#iqos3'" class="tabs">
                        <div @click="faq('31')" class="tab text-left">
                            <span>
                                Cum pot verifica nivelul de încărcare al bateriei?
                                <i
                                    v-if="faqt == '31'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '31'" class="icon icon--minus"></i>
                            </span>
                            <div
                                v-if="faqt == '31'"
                            >"Poți verifica cu ușurință nivelul bateriei urmând acești pași simpli: 1. Apasă butonul de încărcare al încărcătorului portabil pentru 1 secundă (apăsați scurt) 2. Indicatoarele de nivel al bateriei se vor aprinde"</div>
                        </div>
                        <div @click="faq('32')" class="tab text-left">
                            <span>
                                Cât durează încărcarea completă a dispozitivului?
                                <i
                                    v-if="faqt == '32'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '32'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '32'"
                            >Este nevoie de aproximativ 120 de minute pentru a încărca complet încărcătorul de buzunar IQOS 3 de la o priză electrică. Încarcă dispozitivul IQOS 3 utilizând adaptorul de alimentare AC și cablul USB.</div>
                        </div>
                        <div @click="faq('33')" class="tab text-left">
                            <span>
                                Ar trebui să îmi încarc complet întotdeauna bateria încărcătorului de buzunar?
                                <i
                                    v-if="faqt == '33'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '33'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '33'"
                            >Dispozitivul IQOS 3 nu necesită o încărcare completă pentru a funcționa. Dacă încărcătorul de buzunar IQOS 3 este încărcat complet, poți utiliza suportul de până la 20 de ori.</div>
                        </div>

                        <div @click="faq('34')" class="tab text-left">
                            <span>
                                Pot încărca suportul 2.4/2.4P cu încărcătorul de buzunar IQOS 3 și invers?
                                <i
                                    v-if="faqt == '34'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '34'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '34'"
                            >Nu, noul încărcător portabil IQOS 3 nu este compatibil cu versiunile anterioare. Dacă introduci holder-ul (”stiloul”) 2.4+ în încărcătorul portabil IQOS 3, suportul nu se va încărca din cauza dimensiunilor diferite. Holder-ul (”stiloul”) IQOS 3 nu poate fi introdus în încărcătorul portabil 2.4/2.4, datorită dimensiunilor diferite.</div>
                        </div>

                        <div @click="faq('35')" class="tab text-left">
                            <span>
                                Pot folosi adaptorul de alimentare AC și cablul USB de la versiunea anterioară a dispozitivului pentru a încărca dispozitivul IQOS 3?
                                <i
                                    v-if="faqt == '35'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '35'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '35'"
                            >Poți utiliza adaptorul de alimentare AC IQOS din versiunile anterioare ale dispozitivului. Cu toate acestea, cablul USB IQOS 3 este un cablu de tip USB-C, diferit de versiunile anterioare.</div>
                        </div>
                        <div @click="faq('36')" class="tab text-left">
                            <span>
                                Cum pot reseta dispozitivul IQOS?
                                <i
                                    v-if="faqt == '36'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '36'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '36'"
                            >Poți reseta cu ușurință dispozitivul IQOS 3 ținând apăsat butonul încărcătorului portabil timp de 8 secunde, apoi eliberându-l. Indicatorii de nivel al bateriei vor clipi de două ori, apoi se vor stinge progresiv pentru a confirma resetarea.</div>
                        </div>

                        <div @click="faq('37')" class="tab text-left">
                            <span>
                                Ce înseamnă vibrațiile dispozitivului?
                                <i
                                    v-if="faqt == '37'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '37'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '37'"
                            >"Vibrațiile holder-ului (”stiloului”) sunt activate în următoarele situații: - La pornirea încălzirii (odată ce apeși butonul holder-ului (”stiloului”) pentru a începe experiența) - Când experiența este aproape completă (30 de secunde înainte de sfârșit) "</div>
                        </div>
                        <div @click="faq('38')" class="tab text-left">
                            <span>
                                Ce semnifică ledurile de pe holder (”stilou”)?
                                <i
                                    v-if="faqt == '38'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '38'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '38'"
                            >QOS 2.4Plus are următoarele caracteristici: • Holder (”stilou”) IQOS: încărcare mai rapidă (până la 35% mai rapidă) • Vibrația holder-ului (”stiloului”) IQOS • încărcător portabil robust (dispozitiv de blocare îmbunătățit) • leduri mai luminoase pe încărcătorul portabil IQOS • Curățarea automată înlocuiește curățarea manuală Vibrația holder-ului (”stiloului”) IQOS: • Vibrația începe și ledurile clipesc când apeși și ții apăsat butonul de încălzire. Astfel se indică începutul experienței (timp de 6 minute sau 14 fumuri, oricare dintre acestea apare mai întâi). • Ledurile încep să clipească în același timp cu vibrația când au rămas 30 de secunde sau două pufuri. Acest lucru indică faptul că holder-ul (”stiloul”) IQOS se va opri automat. Curățarea automată: • Curățarea automată este declanșată automat la fiecare 20 de încărcări, după ce holder-ul (”stiloul”) IQOS a terminat procesul de încărcare. • În timpul curățării automate, vei observa un proces de încărcare mai lung al holder-ului (”stiloului”) (reîncărcare - curățare - reîncărcare), iar ledurile vor clipi constant.</div>
                        </div>
                        <div @click="faq('39')" class="tab text-left">
                            <span>
                                Ce înseamnă ledurile de pe încărcătorul portabil?
                                <i
                                    v-if="faqt == '39'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '39'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '39'"
                            >Orice lumină albă indică faptul că dispozitivul efectuează diferite operații, cum ar fi încărcarea, repornirea, momentul când e gata de utilizare, încărcarea completă etc. O lumină roșie intermitentă poate apărea: • Dacă este aprins numai indicatorul luminos al încărcătorului portabil: dispozitivul este în afara temperaturii de funcționare (0 - 50°C).</div>
                        </div>
                        <div @click="faq('311')" class="tab text-left">
                            <span>
                                Ce este noul sistem ProtectPlus™?
                                <i
                                    v-if="faqt == '311'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '311'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '311'"
                            >"Sistemul ProtectPlus™ găzduiește o serie de îmbunătățiri tehnologice: - carcasa exterioară robustă îmbunătățește rezistența la șoc a dispozitivului - o nouă deschidere a ușii laterale testată pentru a garanta o performanță sigură - noua tehnologie oferă o durată mai lungă de viață a bateriei - camera de încălzire a suportului a fost reproiectată pentru o fiabilitate îmbunătățită"</div>
                        </div>
                        <div @click="faq('312')" class="tab text-left">
                            <span>
                                Care este temperatura optimă de funcționare a dispozitivului IQOS?
                                <i
                                    v-if="faqt == '312'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '312'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '312'"
                            >IQOS 3 este proiectat să funcționeze într-o gamă largă de temperaturi, dar recomandăm utilizarea între 12℃ și 50 ℃ pentru o experiență optimă.</div>
                        </div>
                        <div @click="faq('313')" class="tab text-left">
                            <span>
                                Ce este sistemul de auto-curățare a lamelei de încălzire?
                                <i
                                    v-if="faqt == '313'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '313'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '313'"
                            >"Procesul de auto-curățare a lamelei de încălzire este activat automat după fiecare 20 de sesiuni, atunci când holder-ul (”stiloul”) se încarcă. Acest proces reduce reziduurile de tutun de pe lamelă, facilitând procesul de curățare manuală, asigurând în același timp cea mai bună experiență de savurare a tutunului. Procesul de auto-curățare al lamelei poate fi activat și prin intermediul aplicației IQOS Connect la cerere."</div>
                        </div>
                    </div>
                </transition>
            </div>

            <label class="faq-title" @click="deschide('#multi')">
                IQOS 3
                MULTI
                <i v-if="deschis != '#multi'" class="arrow arrow-down"></i>
                <i v-if="deschis == '#multi'" class="arrow arrow-up"></i>
            </label>
            <div
                :class="this.$route.hash == '#multi' ?'tab--dev text-center order-first':'tab--dev text-center order-5'"
            >
                <transition name="accordion">
                    <div v-if="deschis == '#multi'" class="tabs">
                        <div @click="faq('multi1')" class="tab text-left">
                            <span>
                                Cum pot verifica nivelul de încărcare al bateriei?
                                <i
                                    v-if="faqt == 'multi1'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'multi1'" class="icon icon--minus"></i>
                            </span>
                            <div
                                v-if="faqt == 'multi1'"
                            >Poți verifica cu ușurință nivelul de încărcare urmând acești pași simpli: 1. Apăsați butonul multifuncțional timp de 1 secundă 2. Indicatoarele de nivel al bateriei se vor aprinde, indicând nivelul de încărcare.</div>
                        </div>
                        <div @click="faq('multi2')" class="tab text-left">
                            <span>
                                Cât durează încărcarea completă a dispozitivului?
                                <i
                                    v-if="faqt == 'multi2'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'multi2'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'multi2'"
                            >Este nevoie de aproximativ 75 de minute pentru a încărca complet dispozitivul IQOS 3 Multi de la o priză electrică. Încarcă dispozitivul IQOS 3 Multi numai utilizând adaptorul de alimentare AC și cablul USB.</div>
                        </div>
                        <div @click="faq('multi3')" class="tab text-left">
                            <span>
                                Ar trebui să îmi încarc complet întotdeauna încărcătorul portabil?
                                <i
                                    v-if="faqt == 'multi3'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'multi3'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'multi3'"
                            >Dispozitivul IQOS 3 Multi nu necesită o încărcare completă pentru a funcționa. Dacă dispozitivul este încărcat complet, poți avea parte de până la 10 sesiuni consecutive fără reîncărcare.</div>
                        </div>
                        <div @click="faq('multi4')" class="tab text-left">
                            <span>
                                Cum pot reseta dispozitivul IQOS?
                                <i
                                    v-if="faqt == 'multi4'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'multi4'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'multi4'"
                            >Poți reseta cu ușurință dispozitivul IQOS 3 Multi apăsând și ținând apăsat butonul încărcătorului portabil timp de 10 secunde. Ledurile se vor stinge/aprinde încet pentru a confirma o resetare.</div>
                        </div>
                        <div @click="faq('multi5')" class="tab text-left">
                            <span>
                                De ce vibrează dispozitivul?
                                <i
                                    v-if="faqt == 'multi5'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'multi5'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'multi5'"
                            >Vibrațiile IQOS 3 Multi sunt activate în următoarele situații: - La pornirea încălzirii (odată ce apeși butonul suportului pentru a porni) - Când experiența este aproape completă (30 de secunde înainte de sfârșit).</div>
                        </div>
                        <div @click="faq('multi6')" class="tab text-left">
                            <span>
                                Ce înseamnă ledurile de pe IOQS 3 Multi?
                                <i
                                    v-if="faqt == 'multi6'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'multi6'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'multi6'"
                            >Orice lumină albă indică faptul că dispozitivul efectuează diferite operații, cum ar fi încărcarea, repornirea, faptul că e gata pentru utilizare, încărcare completă etc. Orice lumină roșie indică faptul că există o eroare a dispozitivului sau dispozitivul funcționează în afara temperaturii de funcționare (0 - 50°C). Te rugăm să efectuezi o resetare a dispozitivului.</div>
                        </div>
                    </div>
                </transition>
            </div>

            <label class="faq-title" @click="deschide('#iqos24')">
                IQOS
                2.4 +
                <i v-if="deschis != '#iqos24'" class="arrow arrow-down"></i>
                <i v-if="deschis == '#iqos24'" class="arrow arrow-up"></i>
            </label>
            <div
                :class="this.$route.hash == '#iqos24' ?'tab--dev text-center order-1':'tab--dev text-center order-3'"
            >
                <transition name="accordion">
                    <div v-if="deschis == '#iqos24'" class="tabs">
                      
                        <div @click="faq('242')" class="tab text-left">
                            <span>
                                Cum pot verifica nivelul de încărcare al bateriei?
                                <i
                                    v-if="faqt == '242'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '242'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '242'"
                            >Poți verifica cu ușurință nivelul de încărcare urmând acești pași simpli: 1. Apasă butonul ON/OFF timp de o secundă. 2. LED-urile se vor aprinde și vor indica nivelul de încărcare: - 1 LED = nivel redus de încărcare - 4 LED-uri = dispozitivul este complet încărcat</div>
                        </div>
                        <div @click="faq('243')" class="tab text-left">
                            <span>
                                Cât durează încărcarea completă a dispozitivului?
                                <i
                                    v-if="faqt == '243'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '243'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '243'"
                            >Este nevoie de aproximativ 90 de minute pentru a încărca complet încărcătorul portabil IQOS 2.4 Plus de la o priză electrică. Încarcă dispozitivul IQOS 2.4 Plus numai utilizând adaptorul de alimentare AC și cablul USB.</div>
                        </div>

                        <div @click="faq('244')" class="tab text-left">
                            <span>
                                Ar trebui să îmi încarc complet întotdeauna bateria încărcătorului portabil?
                                <i
                                    v-if="faqt == '244'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '244'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '244'"
                            >Dispozitivul IQOS nu necesită o încărcare completă pentru a funcționa. Însă, dacă încărcătorul portabil este complet încărcat, poți utiliza ”stiloul” de până la 20 de ori.</div>
                        </div>

                        <div @click="faq('245')" class="tab text-left">
                            <span>
                                Cum pot reseta dispozitivul IQOS?
                                <i
                                    v-if="faqt == '245'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '245'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '245'"
                            >Poți efectua cu ușurință o resetare a dispozitivului IQOS 2.4 Plus apăsând simultan și apoi eliberând butoanele Bluetooth și ON/OFF până când toate ledurile clipesc scurt.</div>
                        </div>
                        <div @click="faq('246')" class="tab text-left">
                            <span>
                                Ce înseamnă vibrațiile dispozitivului?
                                <i
                                    v-if="faqt == '246'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '246'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '246'"
                            >Vibrațiile ”stiloului” sunt activate în următoarele situații: - Când procesul de încălzire a pornit - Când experiența este aproape completă (30 de secunde sau 2 pufuri înainte de final)</div>
                        </div>
                        <div @click="faq('247')" class="tab text-left">
                            <span>
                                Ce înseamnă lumina roșie de pe ”stiloul” meu?
                                <i
                                    v-if="faqt == '247'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '247'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '247'"
                            >Lumina roșie care clipește sau rămâne constantă poate indica faptul că: - ”Stiloul” nu este încărcat - Dispozitivul este în afara temperaturii optime de funcționare (10 - 40°C). - Ai apăsat butonul suportului în timpul utilizării - ”Stiloul” are o defecțiune Pentru a rezolva problema, puteți încerca următoarele: a. Verifică dacă încărcătorul portabil IQOS 2.4 Plus este pornit și are suficient curent. Pune ”stiloul” în încărcătorul portabil și așteaptă până când acesta este complet încărcat (până la 4 minute) . c. Asigură-te că contactul electric de la baza ”stiloului” este curățat. d. Încearcă să resetezi dispozitivul. Dacă întâmpini probleme în continuare, te rugăm să contactezi centrul de Asistență clienți 0800030333 (luni - duminică: 08:00 - 22:00), e-mail: contact@iqos.ro, Facebook: https://www.facebook.com/iqos.ro, Twitter: @iqos_support_ro) sau să ceri asistență la cel mai apropiat magazin IQOS.</div>
                        </div>
                        <div @click="faq('248')" class="tab text-left">
                            <span>
                                Ce înseamnă lumina roșie de pe încărcătorul meu portabil?
                                <i
                                    v-if="faqt == '248'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '248'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '248'"
                            >a. Lumina roșie de pe încărcătorul portabil IQOS 2.4 Plus indică o defecțiune a suportului. O lumină roșie care pulsează încet în timpul încărcării înseamnă că bateria suportului atinge sfârșitul ciclului său de viață. b. Indicatorul luminos de jos al bateriei încărcătorului portabil indică o defecțiune a acestuia sau dispozitivul este în afara temperaturii optime de funcționare (10°C - 40°C). Încearcă să resetezi dispozitivul și asigură-te că acesta se află în intervalul optim de temperatură. Dacă întâmpini probleme în continuare, te rugăm să contactezi centrul de Asistență clienți 0800030333 (luni - duminică: 08:00 - 22:00), e-mail: contact@iqos.ro, Facebook: https://www.facebook.com/iqos.ro, Twitter: @iqos_support_ro) sau să ceri asistență la cel mai apropiat magazin IQOS.</div>
                        </div>
                        <div @click="faq('249')" class="tab text-left">
                            <span>
                                Care este temperatura optimă de funcționare a dispozitivului IQOS?
                                <i
                                    v-if="faqt == '249'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != '249'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == '249'"
                            >IQOS 2.4 Plus este proiectat să funcționeze într-o gamă largă de temperaturi, dar recomandăm utilizarea între 10℃ și 40 ℃ pentru o experiență optimă.</div>
                        </div>
                    </div>
                </transition>
            </div>

            <label class="faq-title" @click="deschide('#despre')">
                Despre IQOS
                <i v-if="deschis != '#despre'" class="arrow arrow-down"></i>
                <i v-if="deschis == '#despre'" class="arrow arrow-up"></i>
            </label>
            <div
                :class="this.$route.hash == '#despre' ?'tab--dev text-center order-1':'tab--dev text-center order-2'"
            >
                <transition name="accordion">
                    <div v-if="deschis == '#despre'" class="tabs">

                          <div @click="faq('despre6')" class="tab text-left">
                            <span>
                                IQOS se încadrează în categoria ţigărilor electronice? Cum se deosebeşte?
                                <i
                                    v-if="faqt == 'despre6'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre6'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre6'"
                            >IQOS nu este o țigară electronică, ci un aparat electronic care încălzeste, nu arde, produsele din tutun comercializate sub numele de HEETS. Spre deosebire de țigările electronice, utilizarea IQOS nu implică utilizarea unor lichide (de exemplu, lichid care conține nicotină) pentru a inhala, ulterior, prin intermediul unui muștiuc, vapori ce conțin nicotină. HEETS nu sunt flacoane de reumplere a țigaretei electronice, ci un produs din tutun reglementat separat din punct de vedere legal.</div>
                        </div>

                        <div @click="faq('despre7')" class="tab text-left">
                            <span>
                                Ce conține o rezervă de tutun HEETS?
                                <i
                                    v-if="faqt == 'despre7'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre7'" class="icon icon--minus"></i>
                            </span>

                            <div v-if="faqt == 'despre7'">
                                IQOS este o alternativă la fumatul obișnuit deoarece încălzește tutunul în loc să îl ardă. Oferă gust autentic de tutun fără foc, fără fum de țigară și cu mai puțin miros. Trecerea completă la IQOS prezintă un risc redus pentru sănătatea ta față de continuarea fumatului.*
                                <br />IQOS eliberează cu 95% mai puține substanțe nocive, în comparație cu țigările.*
                                <br />Informație importantă: IQOS nu este lipsit de riscuri. Emană nicotină, substanță ce dă dependență.
                                <br />*Reduceri medii ale nivelurilor unei game largi de substanțe nocive (mai puțin nicotina) în comparație cu fumul emanat de o țigară de referință (3R4F). Aceasta nu înseamnă neapărat că există o reducere cu 95% a riscurilor. IQOS nu este lipsit de riscuri.
                            </div>
                        </div>

                         <div @click="faq('despre8')" class="tab text-left">
                            <span>
                                Este adevărat că IQOS nu arde tutunul și doar îl încălzește? Care este temperatura de funcționare a „stiloului” IQOS?
                                <i
                                    v-if="faqt == 'despre8'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre8'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre8'"
                            >IQOS include un aparat electronic, denumit de consumatori „stilou”, care încălzește tutunul conținut într-un HEETS, la o temperatură mai mică de 350°C, fără a-l arde, pentru a crea un aerosol ce conține nicotină. Prin comparație, tutunul dintr-o țigară cu aprindere ajunge la o temperatură de până la 800°C.</div>
                        </div>

                        <div @click="faq('despre9')" class="tab text-left">
                            <span>
                                IQOS generează fum? Dar scrum?
                                <i
                                    v-if="faqt == 'despre9'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre9'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre9'"
                            >IQOS doar încălzește tutunul în loc să-l ardă si astfel nu generează nici fum si nici scrum. Încălzirea, în loc de arderea tutunului, eliberează un aerosol care permite adulților fumători să se bucure de aroma autentică a tutunului, oferind o experiență mai curată comparativ cu țigările. IQOS are astfel si un miros mai puțin evident. Spre deosebire de fumul expirat din ţigări, aerosolul generat de HEETS™ este mai puţin supărător pentru cei din jur și se disipează rapid.</div>
                        </div>

                        <div @click="faq('despre10')" class="tab text-left">
                            <span>
                                Este folosirea IQOS sigură/fără riscuri?
                                <i
                                    v-if="faqt == 'despre10'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre10'" class="icon icon--minus"></i>
                            </span>

                            <div v-if="faqt == 'despre10'">
                                IQOS eliberează cu 95% mai puține substanțe nocive, în comparație cu țigările.*
                                <br />Informație importantă: Aceasta nu înseamnă neapărat că există o reducere cu 95% a riscurilor. IQOS nu este lipsit de riscuri.
                                <br />*Reduceri medii ale nivelurilor unei game largi de substanțe nocive (mai puțin nicotina) în comparație cu fumul emanate de o țigară de referință (3R4F).
                            </div>
                        </div>

                         <div @click="faq('despre11')" class="tab text-left">
                            <span>
                                Pot utiliza o ţigară convenţională cu „stiloul” IQOS?
                                <i
                                    v-if="faqt == 'despre11'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre11'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre11'"
                            >Nu. „Stiloul” IQOS este conceput să funcționeze doar cu HEETS. Țigările cu aprindere nu vor funcționa cu sistemul IQOS.</div>
                        </div>

                        <div @click="faq('despre25')" class="tab text-left">
                            <span>
                                Cât timp poate fi consumat un HEETS după ce este încălzit?
                                <i
                                    v-if="faqt == 'despre25'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre25'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre25'"
                            >Sistemul IQOS este conceput pentru a încălzi rezerva de tutun HEETS pentru un interval de timp şi un număr de experiențe aproximativ similar cu al unei țigări combustibile, dar fără a arde tutunul. Puteți utiliza în mod continuu HEETS timp de aproximativ 6 minute sau 14 inhalări, oricare survine prima.</div>
                        </div>

                          <div @click="faq('despre23')" class="tab text-left">
                            <span>
                                De câte ori îmi pot încărca „stiloul” IQOS cu ajutorul încărcătorului portabil înainte de a fi nevoie de reîncărcarea acestuia?
                                <i
                                    v-if="faqt == 'despre23'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre23'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre23'"
                            >Poţi reîncărca „stiloul” IQOS de 20 de ori folosind încărcătorul portabil IQOS complet încărcat. Astfel te poţi bucura de 20 de HEETS™ înainte de reîncărcarea încărcătorului portabil.</div>
                        </div>

                        <div @click="faq('despre5')" class="tab text-left">
                            <span>
                                Doar ce am achiziționat dispozitivul IQOS. Oferiți asistență personalizată? Este gratuită?
                                <i
                                    v-if="faqt == 'despre5'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre5'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre5'"
                            >Încurajăm utilizatorii adulți IQOS să apeleze la centrul de Asistență Clienți (luni - duminică: 08:00 - 22:00). Agenții noștri vor fi alături de tine pentru a îți răspunde la întrebări prin telefon, e-mail, Facebook (https://www.facebook.com/iqos.ro) și Twitter (https://www.twitter.com_iqos_support_ro), în funcție de preferințele tale.</div>
                        </div>

                          <div @click="faq('despre3')" class="tab text-left">
                            <span>
                                Mă ajută IQOS să renunț la fumat?
                                <i
                                    v-if="faqt == 'despre3'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre3'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre3'"
                            >IQOS/HEETS nu sunt o alternativă pentru renunțarea la fumat. Cea mai bună alegere pentru consumatorii preocupați de sănătatea lor este să renunțe complet la tutun.</div>
                        </div>

                           <div @click="faq('despre12')" class="tab text-left">
                            <span>
                                Pot încărca IQOS şi în maşină? Aveţi produse care să facă posibil acest lucru?
                                <i
                                    v-if="faqt == 'despre12'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre12'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre12'"
                            >Încărcătorul portabil IQOS poate fi utilizat în mașinile care dețin port de încărcare prin conectarea cablului USB oferit împreună cu IQOS.</div>
                        </div>

                          <div @click="faq('despre13')" class="tab text-left">
                            <span>
                                Cum pot întreţine zilnic în mod corespunzător acest produs?
                                <i
                                    v-if="faqt == 'despre13'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre13'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre13'"
                            >Recomandăm curăţarea zilnică a lamei de încălzire a „stiloului”şi a capacului utilizând peria de curăţare. De asemenea, produsul se curăţă automat printr-un proces termic după fiecare 20 HEETS utilizate, ca parte a unui proces de întreţinere regulat. Pentru detalii suplimentare poţi consulta ghidul utilizatorului din pachet sau poţi accesa www.IQOS.ro, secţiunea Tutoriale produse.</div>
                        </div>

                        <div @click="faq('despre16')" class="tab text-left">
                            <span>
                                Accesoriile unui dispozitiv IQOS sunt compatibile cu alte dispozitive IQOS?
                                <i
                                    v-if="faqt == 'despre16'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre16'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre16'"
                            >Doar accesoriile IQOS 2.4+ sunt compatibile cu IQOS 2.4, cele două dispozitive având aceleași dimensiuni. În cazul IQOS 3 și IQOS 3 Multi, accesoriile nu sunt compatibile cu nicio altă versiune a dispozitivului și pot fi folosite doar cu dispozitivul pentru care au fost destinate.</div>
                        </div>

                         <div @click="faq('despre18')" class="tab text-left">
                            <span>
                                Ce trebuie să fac dacă o parte a sistemului IQOS nu mai funcţionează?
                                <i
                                    v-if="faqt == 'despre18'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre18'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre18'"
                            >Pentru mai multe detalii legate de serviciile şi condiţiile de înlocuire poți contacta gratuit Centrul pentru Asistență Clienţi la numărul 0800.030.333, zilnic, între orele 08:00 – 22:00. În momentul apelului este necesar să comunici colegilor noștri codul de identificare a „stiloului”/încărcătorului afectat (îl poți găsi inscripționat pe componenta respectivă). După apelul tău, în cazul în care va fi necesară înlocuirea sistemului tău IQOS, regăsești aici punctele de asistență https://www.iqos.ro/iqos-service-points.html. Alternativ, poți merge direct în magazinele proprii IQOS accesând https://www.iqos.ro/unde-gasesc-iqos, secțiunea IQOS Stores</div>
                        </div>

                         <div @click="faq('despre24')" class="tab text-left">
                            <span>
                                Cât timp ar trebui să funcţioneze IQOS?
                                <i
                                    v-if="faqt == 'despre24'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre24'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre24'"
                            >„Stiloul” IQOS este conceput să reziste la 7300 de utilizări, dacă este folosit conform instrucțiunilor din Ghidului Utilizatorului care se regăsește în pachetul inițial.</div>
                        </div>

                         <div @click="faq('despre19')" class="tab text-left">
                            <span>
                                Cât timp este valabil programul de garanţie al produsului?
                                <i
                                    v-if="faqt == 'despre19'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre19'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre19'"
                            >Garanţia producătorului este valabilă timp de un an de la data achiziţiei sau 7300 de încărcări ale încărcătorului portabil/7300 de utilizări ale „stiloului” (oricare din acestea survine mai devreme).</div>
                        </div>

                        <div @click="faq('despre21')" class="tab text-left">
                            <span>
                                IQOS îndeplineşte cerinţele de siguranţă aplicabile dispozitivelor electronice?
                                <i
                                    v-if="faqt == 'despre21'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre21'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre21'"
                            >IQOS îndeplinește cerințele legale de siguranță dacă este utilizat în conformitate cu instrucțiunile menționate în Ghidul Utilizatorului care se regăsește în pachetul inițial. „Stiloul” IQOS şi încărcătorul portabil respectă cerințele de siguranță ale UE pentru dispozitive electronice de larg consum, aspect indicat prin marcajul „CE” aplicat pe ambele produse.</div>
                        </div>

                         <div @click="faq('despre26')" class="tab text-left">
                            <span>
                                Unde pot găsi locaţiile care oferă servicii de înlocuire?
                                <i
                                    v-if="faqt == 'despre26'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre26'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre26'"
                            >Pentru mai multe detalii legate de serviciile şi condiţiile de înlocuire poți contacta gratuit Centrul pentru Asistență Clienţi la numărul 0800.030.333, zilnic, între orele 08:00 – 22:00. În momentul apelului este necesar să comunici colegilor noștri codul de identificare a „stiloului”/încărcătorului afectat (îl poți găsi inscripționat pe componenta respectivă). După apelul tău, în cazul în care va fi necesară înlocuirea sistemului tău IQOS, regăsești aici punctele de asistență . Alternativ, poți merge direct în magazinele IQOS, a căror adresă o poți găsi aici, secțiunea IQOS Stores.</div>
                        </div>

                         <div @click="faq('despre27')" class="tab text-left">
                            <span>
                                Unde se află certificatul de garanţie?
                                <i
                                    v-if="faqt == 'despre27'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre27'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre27'"
                            >Certificatul de garanţie însoţește toate componentele IQOS (cu excepţia dispozitivului de curăţare), iar condiţiile de garanţie sunt menţionate în ghidul utilizatorului care se regaseste in pachet.</div>
                        </div>

                        <div @click="faq('despre28')" class="tab text-left">
                            <span>
                                Cine/ce este acoperit de garanția internațională IQOS?
                                <i
                                    v-if="faqt == 'despre28'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre28'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre28'"
                            >Serviciul de asistență specială este aplicabil numai dacă sunt îndeplinite toate cele trei condiții: (i) ești un consumator IQOS adult din UE sau Elveția; (ii) tu și dispozitivul tău IQOS sunteți înregistrați în UE sau în Elveția; și (iii) întâmpini o problemă cu dispozitivul IQOS atunci când călătorești în UE și Elveția, în afara țării în care este înregistrat dispozitivul.</div>
                        </div>

                            <div @click="faq('despre29')" class="tab text-left">
                            <span>
                                Cât timp durează acoperirea în cadrul garanției internaționale?
                                <i
                                    v-if="faqt == 'despre29'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre29'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre29'"
                            >Acest serviciu este oferit în perioada de garanție comercială IQOS, valabilă în țara de achiziție.</div>
                        </div>

                         <div @click="faq('despre31')" class="tab text-left">
                            <span>
                                Cum funcționează serviciul special de garanție internațională?
                                <i
                                    v-if="faqt == 'despre31'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre31'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre31'"
                            >Dacă ai nevoie de asistență în străinătate, contactează, gratuit, numărul de asistență pentru clienți IQOS, *0080023723888 pentru ajutor. Înainte de a efectua apelul, asigură-te că ai la îndemână adresa temporară completă (de exemplu, numele și adresa hotelului). Se pot aplica limitări de conectivitate cu unii operatori de telefonie mobilă. În cazul puțin probabil în care se întâmplă acest lucru, ne poți contacta la +302112116043 (pot fi percepute anumite taxe; te rugăm să consulți operatorul tău pentru detalii).</div>
                        </div>

                         <div @click="faq('despre36')" class="tab text-left">
                            <span>
                                În ce țări se aplică serviciul special de garanție internațională?
                                <i
                                    v-if="faqt == 'despre36'"
                                    class="icon icon--plus"
                                ></i>
                                <i v-if="faqt != 'despre36'" class="icon icon--minus"></i>
                            </span>

                            <div
                                v-if="faqt == 'despre36'"
                            >Serviciul de asistență IQOS este disponibil pentru consumatorii adulți IQOS care locuiesc și călătoresc în următoarele țări: Grecia, Cipru, România, Bulgaria, Regatul Unit, Spania, Portugalia, Olanda, Elveția, Germania, Danemarca, Franța, Italia, Polonia, Lituania, Letonica, Republica Cehă, Slovacia, Croația, Slovenia.</div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/Partials/Header";
import BackDevice from "@/views/Partials/BackDevice";
import { mapActions } from "vuex";

export default {
    components: {
        Header,
        BackDevice
    },
    data: function() {
        return {
            deschis: this.$route.hash,
            faqt: ""
        };
    },
    methods: {
        removeClass() {
            var appBody = document.querySelector("body"),
                btnMenu = document.querySelector(".button-menu");
            appBody.classList.remove("body-menu-active");
            btnMenu.classList.remove("active");
        },
        deschide(text) {
            if (this.deschis == text) this.deschis = "";
            else this.deschis = text;
        },
        faq(text) {
            if (this.faqt == text) this.faqt = "";
            else this.faqt = text;
        },
        ...mapActions({
            endPageZero: "endPageZero"
        })
    },
    mounted: function() {
        this.endPageZero();
    }
};
</script>
